import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const admin_login = createAsyncThunk(
  "auth/admin_login",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post("/admin/admin_login", info, {
        withCredentials: true,
      });

      if (data.status_code === 0) {

        return fulfillWithValue(data);
      } else if (data.status_code === 1) {
        return rejectWithValue(data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Admin register action
export const admin_register = createAsyncThunk(
  "auth/admin_register",
  async (info, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post("/admin/create_admin", info, {
        withCredentials: true,
      });
      if (data.status_code === 0) {
        return fulfillWithValue(data);
      } else if (data.status_code === 1) {
        return rejectWithValue(data);
      }
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authReducer = createSlice({
  name: "auth",
  initialState: {
    successMessage: "",
    errorMessage: "",
    loader: false,
    adminInfo: null,
    merchantDetails: [],
  },
  reducers: {
    messageClear: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
    },
    logout: (state) => {
      state.adminInfo = null;
      state.successMessage = "";
      state.errorMessage = "";
      sessionStorage.removeItem("admin");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(admin_login.pending, (state) => {
        state.loader = true;
      })
      .addCase(admin_login.rejected, (state, { payload }) => {
        state.loader = false;
        if (payload?.status_code === 1) {
          state.errorMessage = payload.message;
        }
      })
      .addCase(admin_login.fulfilled, (state, { payload }) => {
        state.loader = false;
        if (payload?.status_code === 0) {
          state.successMessage = payload.message;
          const { user_name, email, id } = payload.admin;
          state.adminInfo = {
            username: user_name,
            email: email,
            id: id,
          };

          sessionStorage.setItem("admin", JSON.stringify(state.adminInfo));
        }
      })
      .addCase(admin_register.pending, (state) => {
        state.loader = true;
      })
      .addCase(admin_register.rejected, (state, { payload }) => {
        state.loader = false;
        if (payload?.status_code === 1) {
          state.errorMessage = payload.message;
        }
      })
      .addCase(admin_register.fulfilled, (state, { payload }) => {
        state.loader = false;
        if (payload?.status_code === 0) {
          state.successMessage = payload.message;
        }
      });
  },
});

export const { messageClear, logout } = authReducer.actions;
export default authReducer.reducer;
