import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

export const categoryAdd = createAsyncThunk(
  "category/categoryAdd",
  async (
    { categoryName, description },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const categoryData = { categoryName, description };
      const { data } = await api.post(
        "/categories/add_categories",
        categoryData,
        {
          withCredentials: true,
        }
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "category/updateCategory",
  async (
    { categoryId, categoryName, description },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const categoryData = { categoryName, description };
      const { data } = await api.post(
        `/categories/update_categories/${categoryId}`,
        categoryData,
        {
          withCredentials: true,
        }
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async (categoryId, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.post(
        `/categories/delete_categories/${categoryId}`,
        {
          withCredentials: true,
        }
      );
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const fetchCategories = createAsyncThunk(
  "category/fetchCategories",
  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await api.get("/categories/all_categories", {
        withCredentials: true,
      });
      return fulfillWithValue(data);
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const categoryReducer = createSlice({
  name: "category",
  initialState: {
    successMessage: "",
    errorMessage: "",
    addSuccessMessage: "",
    addErrorMessage: "",
    loader: false,
    categories: [],
  },
  reducers: {
    messageClear: (state) => {
      state.errorMessage = "";
      state.successMessage = "";
      state.addErrorMessage = "";
      state.addSuccessMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(categoryAdd.pending, (state) => {
        state.loader = true;
      })
      .addCase(categoryAdd.rejected, (state, { payload }) => {
        state.loader = false;
        state.addErrorMessage = payload.message;
      })
      .addCase(categoryAdd.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.addSuccessMessage = payload.message;
        state.categories.push({
          id: payload.categoryId,
          categoryName: payload.categoryName,
          description: payload.description,
        });
      })
      .addCase(fetchCategories.pending, (state) => {
        state.loader = true;
      })
      .addCase(fetchCategories.rejected, (state, { payload }) => {
        state.loader = false;
        state.errorMessage = payload.message;
      })
      .addCase(fetchCategories.fulfilled, (state, { payload }) => {
        state.loader = false;
        state.categories = payload.categories;
      })
      .addCase(updateCategory.pending, (state) => {
        state.loader = true;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.loader = false;
        state.successMessage = "Category updated successfully";
        const index = state.categories.findIndex(
          (category) => category.category_id === action.payload.category_id
        );
        if (index !== -1) {
          state.categories[index] = action.payload;
        }
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.loader = false;
      })
      .addCase(deleteCategory.pending, (state) => {
        state.loader = true;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.loader = false;
        state.categories = state.categories.filter(
          (cat) => cat.category_id !== action.payload.category_id
        );
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.loader = false;
        state.errorMessage = action.payload.message;
      });
  },
});

export const { messageClear } = categoryReducer.actions;
export default categoryReducer.reducer;
